/* assets/fonts.css */

@import url('https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&family=Bubblegum+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Reem+Kufi:wght@400..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/weston');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/alegreya-sc');

/* @font-face {
  font-family: 'Weston Free';
  src: url('https://cdn.shopify.com/s/files/1/0879/3872/8250/files/Weston-Free.woff2?v=1734030512') format('woff2'),
       url('https://cdn.shopify.com/s/files/1/0879/3872/8250/files/Weston-Free.woff?v=1734030512') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Weston Free';
  src: url('https://cdn.shopify.com/s/files/1/0879/3872/8250/files/Weston-Light-Free.woff2?v=1734030512') format('woff2'),
       url('https://cdn.shopify.com/s/files/1/0879/3872/8250/files/Weston-Light-Free.woff?v=1734030512') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Adorn Serif';
  src: url('https://texasgiftbaskets.com/cdn/shop/t/1/assets/AdornSerif.woff2') format('woff2'),
       url('https://texasgiftbaskets.com/cdn/shop/t/1/assets/AdornSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 700;
  src: url('https://texasgiftbaskets.com/cdn/shop/t/1/assets/alegreya-sc') format('woff2');
}
